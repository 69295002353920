import './EventsPage.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import React, { useState, useEffect } from 'react';
import { EventInput, EventClickArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import EventModal from '../components/EventsPage/EventModal';
import { getEventRange } from '../components/Utils/EventUtil';

function EventsPage() {
  const [events, setEvents] = useState<EventInput>([]);
  const [selectedEvent, selectEvent] = useState<EventImpl>();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setEvents([
      {
        title: 'Training',
        description: 'Treffpunkt ist 14:45 Uhr vor dem Eingang zum Hallenbad.',
        contactPerson: null,
        location: 'Rebmeerbad, Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern',
        start: '2025-01-11T14:45:00',
        end: '2025-01-11T16:45:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Mitgliederversammlung',
        description:
          'Jährliche Mitgliederversammlung des Kanuverein Südliche Weinstraße e.V.',
        contactPerson: 'Max Reinhardt',
        location: 'Bootshaus, Auf dem Viertel 11, 76887 Bad Bergzabern',
        start: '2025-02-02T14:00:00',
        end: '2025-02-02T16:00:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Fahrtenplanung 2025',
        description: 'Fahrtenplanung für das Jahr 2025.',
        contactPerson: 'Max Reinhardt',
        location: 'Bootshaus, Auf dem Viertel 11, 76887 Bad Bergzabern',
        start: '2025-02-02T16:00:00',
        end: '2025-02-02T17:00:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Kanu Freestyle Trainingslager und Freizeit (Ostern)',
        description:
          'Teilnehmen können Kids mit und ohne Begleitung, die ins Kanu Freestyle einsteigen wollen. Kids müssen Sicher im Boot sitzen und rollen können. Teilnahme mit eigenen Boot oder nach Absprache können passende Freestyle Boote ausgeliehen werden. Programm: Boot und Material im Freestyle, Sicherheit und Prävention, Fahren im Wildwasser und Welle, grundlegende Moves auf dem Flachwasser und in der Welle.',
        location: 'St. Pierre (Frankreich)',
        contactPerson: 'Leon Bast',
        start: '2025-04-12',
        end: '2025-04-20',
        allDay: true,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Youth Freestyle Kayak Camp',
        description: '',
        location: 'Plattling (Deutschland)',
        contactPerson: 'Leon Bast',
        start: '2025-05-01',
        end: '2025-05-05',
        allDay: true,
        backgroundColor: '#f1ab2a',
        borderColor: '#e79807',
        textColor: '#000',
      },
      {
        title: 'Kanu Freestyle Trainingslager und Freizeit (Sommer)',
        description:
          'Teilnehmen können Kids mit und ohne Begleitung, die ins Kanu Freestyle einsteigen wollen. Kids müssen Sicher im Boot sitzen und rollen können. Teilnahme mit eigenen Boot oder nach Absprache können passende Freestyle Boote ausgeliehen werden. Programm: Boot und Material im Freestyle, Sicherheit und Prävention, Fahren im Wildwasser und Welle, grundlegende Moves auf dem Flachwasser und in der Welle.',
        location: 'St. Pierre (Frankreich)',
        contactPerson: 'Leon Bast',
        start: '2025-09-06',
        end: '2025-09-14',
        allDay: true,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Kanu Freestyle Trainingslager und Freizeit (Herbst)',
        description:
          'Teilnehmen können Kids mit und ohne Begleitung, die ins Kanu Freestyle einsteigen wollen. Kids müssen Sicher im Boot sitzen und rollen können. Teilnahme mit eigenen Boot oder nach Absprache können passende Freestyle Boote ausgeliehen werden. Programm: Boot und Material im Freestyle, Sicherheit und Prävention, Fahren im Wildwasser und Welle, grundlegende Moves auf dem Flachwasser und in der Welle.',
        location: 'St. Pierre (Frankreich)',
        contactPerson: 'Leon Bast',
        start: '2025-10-25',
        end: '2025-11-02',
        allDay: true,
        backgroundColor: '#0082cb',
      },
    ]);
  }, []);

  const eventClick = (eventClickInfo: EventClickArg) => {
    selectEvent(eventClickInfo.event);
    handleShow();
  };

  return (
    <div>
      <h2>Fahrten und Aktionen</h2>

      <FullCalendar
        plugins={[dayGridPlugin, bootstrap5Plugin, multiMonthPlugin]}
        initialView="dayGridMonth"
        themeSystem="bootstrap5"
        locale="de"
        height="auto"
        firstDay={1}
        buttonText={{
          today: 'Heute',
          month: 'Monatsansicht',
          week: 'Woche',
          day: 'Tag',
          list: 'Liste',
          year: 'Jahresansicht',
        }}
        headerToolbar={{
          left: 'prev,next,today',
          center: 'title',
          right: 'dayGridMonth,multiMonthYear',
        }}
        eventClick={eventClick}
        events={events}
      />

      <h2>Übersicht:</h2>
      <table className="events-table">
        <tbody>
          {events.map((event: any) => (
            <tr key={event.start}>
              <td>{getEventRange(event.start, event.end, event.allDay)}</td>
              <td>{event.title}</td>
              {event.contactPerson ? (
                <td>
                  <i>({event.contactPerson})</i>
                </td>
              ) : (
                <td></td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      <EventModal
        show={show}
        handleClose={handleClose}
        event={selectedEvent}
      ></EventModal>
    </div>
  );
}

export default EventsPage;
