import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import './EventModal.css';
import { EventImpl } from '@fullcalendar/core/internal';
import { getEventRange } from '../Utils/EventUtil';

type Props = {
  show: boolean;
  handleClose: () => void;
  event: EventImpl | undefined;
};

function EventModal(props: Props) {
  var eventRange = getEventRange(
    props.event?.start,
    props.event?.end,
    props.event?.allDay,
  );

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.event?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="events-modal-table">
            <tbody>
              <tr>
                <td>
                  <b>Termin:</b>
                </td>
                <td>{eventRange}</td>
              </tr>
              {props.event?.extendedProps.location && (
                <tr>
                  <td>
                    <b>Ort:</b>
                  </td>
                  <td>{props.event?.extendedProps.location}</td>
                </tr>
              )}
              {props.event?.extendedProps.contactPerson && (
                <tr>
                  <td>
                    <b>Ansprechpartner:</b>
                  </td>
                  <td>{props.event?.extendedProps.contactPerson}</td>
                </tr>
              )}
              {props.event?.extendedProps.description && (
                <tr>
                  <td>
                    <b>Beschreibung:</b>
                  </td>
                  <td>{props.event?.extendedProps.description}</td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.handleClose}>Schließen</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EventModal;
